// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FetchResourceProvidersRequest from "./requests/FetchResourceProvidersRequest.res.js";

var fetchResourceProviders = FetchResourceProvidersRequest.exec;

export {
  fetchResourceProviders ,
}
/* FetchResourceProvidersRequest Not a pure module */
