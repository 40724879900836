// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Toggle from "../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Breadcrumb from "../../../styleguide/components/Breadcrumb/Breadcrumb.res.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as Api_Resource from "../../../api/resources/Api_Resource.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_Resource from "../../../routes/common/Routes_Resource.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourceEditNavbar from "../resource-edit-navbar/ResourceEditNavbar.res.js";
import * as ResourceProvidersScss from "./ResourceProviders.scss";
import * as ResourceProvidersActionBar from "./ResourceProvidersActionBar.res.js";

var css = ResourceProvidersScss;

var initialState_selection = ID.$$Set.make();

var initialState_entityUpdates = ID.$$Map.make();

var initialState = {
  status: "FetchingResourceProviders",
  currentPage: 1,
  totalPages: 2,
  search: "",
  selection: initialState_selection,
  entityUpdates: initialState_entityUpdates,
  batchUpdate: undefined,
  batchUpdateError: false,
  currentResourceProviderId: undefined
};

function ResourceProviders$default(props) {
  var resourceId = props.resourceId;
  var container = React.useRef(null);
  var searchProviders = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchResourceProviders" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_Resource.fetchResourceProviders(resourceId, state.currentPage, tmp), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedResourceProvidersFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourceProviders",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ResourceProviders.default"
                                          }, "ResourceProviders::FetchResourceProviders::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailResourceProvidersFetch");
                                    }));
                            })
                        };
              case "FailResourceProvidersFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedResourceProvidersFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            currentResourceProviderId: state.currentResourceProviderId
                          }
                        };
              case "ToggleSelectionOfAllItems" :
                  var match = state.status;
                  var match$1 = state.batchUpdate;
                  if (typeof match !== "object") {
                    return "NoUpdate";
                  }
                  if (match$1 !== undefined) {
                    return "NoUpdate";
                  }
                  var resourceProviders = match._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: state.search,
                            selection: resourceProviders.length !== Belt_Set.size(state.selection) ? Belt_Set.fromArray(Belt_Array.map(resourceProviders, (function (provider) {
                                          return provider.id;
                                        })), ID.Comparable) : ID.$$Set.make(),
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: false,
                            currentResourceProviderId: state.currentResourceProviderId
                          }
                        };
              case "DeselectAll" :
                  var match$2 = state.status;
                  var match$3 = state.batchUpdate;
                  if (typeof match$2 !== "object" || match$3 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              currentResourceProviderId: state.currentResourceProviderId
                            }
                          };
                  }
              case "SelectInBatch" :
                  var providers = state.status;
                  if (typeof providers !== "object") {
                    return "NoUpdate";
                  }
                  var providers$1 = providers._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Selecting",
                            batchUpdateError: state.batchUpdateError,
                            currentResourceProviderId: state.currentResourceProviderId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var providerIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (provider) {
                                                          return Caml_obj.equal(id, provider.id);
                                                        }), __x);
                                          })(providers$1);
                                      if (match !== undefined && !match.selected) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api.selectResourceProvidersInBatch(resourceId, providerIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedSelectInBatch",
                                                    _0: providerIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourceProviders",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ResourceProviders.default"
                                          }, "ResourceProviders" + "::SelectInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatch");
                                    }));
                            })
                        };
              case "DeselectInBatch" :
                  var providers$2 = state.status;
                  if (typeof providers$2 !== "object") {
                    return "NoUpdate";
                  }
                  var providers$3 = providers$2._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: "Deselecting",
                            batchUpdateError: state.batchUpdateError,
                            currentResourceProviderId: state.currentResourceProviderId
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              var providerIds = Belt_Array.keep(Belt_Set.toArray(param.state.selection), (function (id) {
                                      var match = (function (__x) {
                                            return Js_array.find((function (provider) {
                                                          return Caml_obj.equal(id, provider.id);
                                                        }), __x);
                                          })(providers$3);
                                      if (match !== undefined && match.selected) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }));
                              $$Promise.wait(Api.deselectResourceProvidersInBatch(resourceId, providerIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedDeselectInBatch",
                                                    _0: providerIds
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "ResourceProviders",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "ResourceProviders.default"
                                          }, "ResourceProviders" + "::DeselectInBatch::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailInBatch");
                                    }));
                            })
                        };
              case "FailInBatch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: undefined,
                            batchUpdateError: true,
                            currentResourceProviderId: state.currentResourceProviderId
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedResourceProvidersFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.resourceProviders
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            currentResourceProviderId: state.currentResourceProviderId
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingResourceProviders") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResourceProviders",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              currentResourceProviderId: state.currentResourceProviderId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResourceProviders");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: action._0,
                            selection: state.selection,
                            entityUpdates: state.entityUpdates,
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            currentResourceProviderId: state.currentResourceProviderId
                          },
                          _1: searchProviders
                        };
              case "PerformSearch" :
                  var match$5 = state.status;
                  if (typeof match$5 !== "object" && match$5 === "FetchingResourceProviders") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingResourceProviders",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              currentResourceProviderId: state.currentResourceProviderId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchResourceProviders");
                              })
                          };
                  }
              case "ToggleSelectionOfItem" :
                  var id = action._0;
                  var match$6 = state.batchUpdate;
                  if (match$6 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: state.status,
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              search: state.search,
                              selection: Belt_Set.has(state.selection, id) ? Belt_Set.remove(state.selection, id) : Belt_Set.add(state.selection, id),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: false,
                              currentResourceProviderId: state.currentResourceProviderId
                            }
                          };
                  }
              case "UpdateSelected" :
                  var action$1 = action._2;
                  var providerId = action._1;
                  var resourceId$1 = action._0;
                  var match$7 = state.status;
                  var match$8 = state.batchUpdate;
                  var match$9 = Belt_Map.get(state.entityUpdates, providerId);
                  if (typeof match$7 !== "object") {
                    match$7 === "FetchingResourceProviders";
                  } else if (match$8 === undefined && match$9 === undefined) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$7._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, providerId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Select" ? true : false;
                                        return {
                                                id: x.id,
                                                name: x.name,
                                                selected: tmp
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              search: state.search,
                              selection: state.selection,
                              entityUpdates: Belt_Map.set(state.entityUpdates, providerId, {
                                    TAG: "SelectedUpdate",
                                    _0: action$1
                                  }),
                              batchUpdate: state.batchUpdate,
                              batchUpdateError: state.batchUpdateError,
                              currentResourceProviderId: state.currentResourceProviderId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Select") {
                                  return $$Promise.wait(Api.selectResourceProvider(resourceId$1, providerId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedUpdateSelected",
                                                              _0: providerId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ResourceProviders",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "ResourceProviders.default"
                                                    }, "ResourceProviders" + "::UpdateProvider::Select::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailUpdateSelected",
                                                      _0: providerId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api.deselectResourceProvider(resourceId$1, providerId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedUpdateSelected",
                                                              _0: providerId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "ResourceProviders",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "ResourceProviders.default"
                                                    }, "ResourceProviders" + "::UpdateProvider::Deselect::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailUpdateSelected",
                                                      _0: providerId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
                  return "NoUpdate";
              case "SucceedUpdateSelected" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            currentResourceProviderId: state.currentResourceProviderId
                          }
                        };
              case "FailUpdateSelected" :
                  var providerId$1 = action._0;
                  var match$10 = state.status;
                  var match$11 = Belt_Map.get(state.entityUpdates, providerId$1);
                  if (typeof match$10 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$11 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$11._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$10._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, providerId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Select" ? true : false;
                                      return {
                                              id: x.id,
                                              name: x.name,
                                              selected: tmp
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            search: state.search,
                            selection: state.selection,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, providerId$1),
                            batchUpdate: state.batchUpdate,
                            batchUpdateError: state.batchUpdateError,
                            currentResourceProviderId: state.currentResourceProviderId
                          }
                        };
              case "SucceedSelectInBatch" :
                  var providerIds = action._0;
                  var providers$4 = state.status;
                  if (typeof providers$4 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(providers$4._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(providerIds)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  selected: true
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              currentResourceProviderId: state.currentResourceProviderId
                            }
                          };
                  }
              case "SucceedDeselectInBatch" :
                  var providerIds$1 = action._0;
                  var providers$5 = state.status;
                  if (typeof providers$5 !== "object") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(providers$5._0, (function (x) {
                                        var id = x.id;
                                        if ((function (__x) {
                                                return Js_array.includes(id, __x);
                                              })(providerIds$1)) {
                                          return {
                                                  id: x.id,
                                                  name: x.name,
                                                  selected: false
                                                };
                                        } else {
                                          return x;
                                        }
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              search: state.search,
                              selection: ID.$$Set.make(),
                              entityUpdates: state.entityUpdates,
                              batchUpdate: undefined,
                              batchUpdateError: false,
                              currentResourceProviderId: state.currentResourceProviderId
                            }
                          };
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchResourceProviders");
        }), []);
  var providers = state.status;
  var tmp;
  if (typeof providers !== "object") {
    tmp = false;
  } else {
    var providers$1 = providers._0;
    tmp = Belt_Set.size(state.selection) === providers$1.length && providers$1.length !== 0;
  }
  var providers$2 = state.status;
  var tmp$1;
  tmp$1 = typeof providers$2 !== "object" ? (
      providers$2 === "FetchingResourceProviders" ? JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Spinner.make, {
                    size: "XL",
                    color: "Teal"
                  }),
              className: Cx.cx([
                    css.tableBody,
                    css.tableBodyMinHeight
                  ])
            }) : JsxRuntime.jsx("div", {
              children: "Something went wrong during the search",
              className: Cx.cx([
                    css.tableBody,
                    css.tableBodyMinHeight,
                    css.fetchError
                  ])
            })
    ) : JsxRuntime.jsx("div", {
          children: Belt_Array.map(providers$2._0, (function (provider) {
                  var selected = provider.selected;
                  return JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Checkbox.make, {
                                            id: "provider--" + (ID.toString(provider.id) + "--selected"),
                                            size: "SM",
                                            checked: Belt_Set.has(state.selection, provider.id),
                                            onChange: (function (param) {
                                                dispatch({
                                                      TAG: "ToggleSelectionOfItem",
                                                      _0: provider.id
                                                    });
                                              })
                                          }),
                                      className: css.selected
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: provider.name,
                                      className: css.provider
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Toggle.make, {
                                            on: selected,
                                            size: "MD",
                                            onChange: (function () {
                                                dispatch({
                                                      TAG: "UpdateSelected",
                                                      _0: resourceId,
                                                      _1: provider.id,
                                                      _2: selected ? "Deselect" : "Select"
                                                    });
                                              })
                                          }),
                                      className: css.selected
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Popover.make, {
                                            children: [
                                              JsxRuntime.jsx(Popover.Trigger.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          children: JsxRuntime.jsx(IconMenu.make, {
                                                                size: "MD",
                                                                color: "Teal"
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(Dropdown.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "RightEdge"
                                                    },
                                                    className: css.dropdownBody,
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          className: css.dropdownBodyRow,
                                                          children: JsxRuntime.jsx(Link.make, {
                                                                href: "#",
                                                                className: css.dropdownBodyLink,
                                                                children: "Assigned Resources"
                                                              })
                                                        })
                                                  })
                                            ]
                                          }),
                                      className: css.resourceMenu
                                    })
                              ],
                              className: css.row
                            }, ID.toString(provider.id));
                })),
          className: css.tableBody
        });
  var providers$3 = state.status;
  var tmp$2;
  if (typeof providers$3 !== "object") {
    tmp$2 = null;
  } else {
    var providers$4 = providers$3._0;
    tmp$2 = JsxRuntime.jsx(ResourceProvidersActionBar.make, {
          selection: Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(state.selection), (function (id) {
                      return Belt_Option.map((function (__x) {
                                      return Js_array.find((function (provider) {
                                                    return Caml_obj.equal(id, provider.id);
                                                  }), __x);
                                    })(providers$4), (function (provider) {
                                    return provider.selected;
                                  }));
                    })), [], (function (acc, selected) {
                  if (selected !== undefined) {
                    return Belt_Array.concat(acc, [selected]);
                  } else {
                    return acc;
                  }
                })),
          error: state.batchUpdateError,
          busy: Belt_Option.isSome(state.batchUpdate),
          deselect: (function () {
              dispatch("DeselectAll");
            }),
          selectInBatch: (function (param) {
              dispatch("SelectInBatch");
            }),
          deselectInBatch: (function (param) {
              dispatch("DeselectInBatch");
            })
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ResourceEditNavbar.make, {
                      selectedCategory: "ResourceProviders",
                      resourceId: resourceId
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Breadcrumb.make, {
                                    breadcrumbLinks: [{
                                        linkName: "Resource Providers: " + props.resourceTitle,
                                        linkPath: Routes_Resource.Dashboard.editResourceProviders(resourceId)
                                      }]
                                  }),
                              className: css.breadcrumbs
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: css.title,
                                      children: "Resource Providers"
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(SearchField.make, {
                                            id: "resource-providers-search",
                                            value: state.search,
                                            inputClassName: css.searchField,
                                            onChange: (function ($$event) {
                                                dispatch({
                                                      TAG: "UpdateSearchInput",
                                                      _0: $$event.target.value
                                                    });
                                              })
                                          }),
                                      className: css.headerControls
                                    })
                              ],
                              className: css.header
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Checkbox.make, {
                                                    id: "resource--all--selected",
                                                    size: "SM",
                                                    checked: tmp,
                                                    onChange: (function (param) {
                                                        dispatch("ToggleSelectionOfAllItems");
                                                      })
                                                  }),
                                              className: css.selected
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Provider",
                                              className: Cx.cx([
                                                    css.resourceProvider,
                                                    css.tableHeaderCell
                                                  ])
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: "Selected",
                                              className: Cx.cx([
                                                    css.selected,
                                                    css.tableHeaderCell
                                                  ])
                                            })
                                      ],
                                      className: Cx.cx([
                                            css.row,
                                            css.tableHeader
                                          ])
                                    }),
                                tmp$1
                              ],
                              className: css.table
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: css.pagination
                              }) : null
                      ]
                    }),
                tmp$2
              ],
              className: css.pageContainer
            });
}

var $$default = ResourceProviders$default;

export {
  css ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
